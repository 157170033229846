import React from "react"
import { Link } from "gatsby"
import Logo from "../components/logo"

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }
  render() {
    return (
      <nav className="navbar is-black" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">

          <Link className="navbar-item" to="/">
            <Logo />
          </Link>

          <div
            role="button"
            className={"navbar-burger burger" + (this.state.isToggleOn ? '' : ' is-active')}
            data-target="navMenu"
            aria-label="menu"
            aria-expanded="false"
            onClick={this.handleClick}
            onKeyDown={this.handleClick}
            tabIndex={0}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        <div
          id="navMenu"
          className={"navbar-menu " + (this.state.isToggleOn ? '' : 'is-active')}
        >
          <div className="navbar-start">
            <Link className="navbar-item" to={`/3dprojects/`}>3D Projects</Link>
            <Link className="navbar-item" to={`/photos/`}>Photos</Link>
            <Link className="navbar-item" to={`/contact/`}>Contact</Link>
          </div>
        </div>

      </nav>
    )
  }
}
